@import "design/variables";

/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* =============================================================================
   HTML5 element display
   ========================================================================== */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
}

audio[controls], canvas, video {
}

/* =============================================================================
   Base

html { -webkit-tap-highlight-color: rgba(0,0,0,0);}


/* =============================================================================
   Links
   ========================================================================== */

a {
    color: #00e;
}

a:visited {
    color: #551a8b;
}

a:focus {
}

/* Improve readability when focused and hovered in all browsers: people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
}

.wrapper, .side-content ,
.apercu-article h2.titre-article, .apercu-article .h2.titre-article,
.single-article .titre-article h1, .single-article .titre-article .h1{
    background: #FFFFFF;
}

.sidebar ul > .actif > a {
    color: #2C3E50;
}

hr {
    border-top: 1px solid #ccc;
}

ins {
    background: #ff9;
    color: #000;
}

mark {
    background: #ff0;
    color: #000;
}

.produits .produit_etiquette:after, .produit_etiquette::after {
    border-color: rgb(37%, 52%, 67%) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produits .produit_etiquette, .produit_etiquette {
    background: rgb(37%, 52%, 67%);
}

.produits .produit_etiquette2, .produit_etiquette2 {
    background: rgb(37%, 52%, 67%);
}

.produits .produit_etiquette2:after, .produit_etiquette2::after {
    border-color: rgba(0, 0, 0, 0) rgb(255, 71, 71) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

/* =============================================================================
   a mettre dans tous les designs
   ========================================================================== */

#nav-static-principal > ul > li {
    border-bottom: 1px solid #222222;
}

/* =============================================================================*/

button, input[type="button"], input[type="reset"], input[type="submit"] {
}

/*
 * Consistent box sizing and appearance
 */

input[type="checkbox"], input[type="radio"] {
}

input[type="search"] {
}

button::-moz-focus-inner, input::-moz-focus-inner {
}

/* Remove default vertical scrollbar in IE6/7/8/9 */
textarea {
}

/* Colors for form validity */
input:valid, textarea:valid {
}

body, .modal-content {
    background-color: #fff;
    color: #222222;
}

header {
    background-color: #fff;
}

.wrapper .menu {
    background-image: none !important;
}

.slogan {
    color: #2C3E50;

}

/*-----------------------------
CONTENU
-------------------------------*/
/*
 * Bloc de contenu de la page
 */

.boutique .produits .remise, #fiche-produit .remise-produit {
    background-color: #2C3E50;
}

#fiche-produit .wrap-description h3,
#fiche-produit .wrap-description .h3 {
    color: #222;
}

#savoirplus h4,
#savoirplus .h4 {
    color: #fff;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #2C3E50;
    border-color: #2C3E50;
}

.pagination > li > a, .pagination > li > span {
    color: #2C3E50;
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

.links_p a {
    color: #2C3E50;
}

/*-----------------------------
ENTETE DE PAGE 
-------------------------------*/

.quantite {
    color: #222222;
}

/*-----------------------------
NAVIGATION 
-------------------------------*/

nav#nav-principal {
    .navigation_link_p{
        color: #fff;
    }
    .navigation_button_p{

        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: #2C3E50;
            .navigation_link_p{

            }
        }
    }
    .sous_navigation_link_p{
        color: #fff;
    }
    .sous_navigation_button_p{
        background-color: #2C3E50;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: #fff;
            > .sous_navigation_link_p{
                color: #2C3E50;
            }
        }
    }
}
@media #{$small-and-down}{
    nav#nav-principal{
        .navigation_button_p{
            background:#5E84AA;
        }
    }
}

nav.secondaire ul li {
    &, & > a {
        color: #000;
    }
    a.actif {
        color: #2C3E50;
    }
}

.bx-controls-direction i {
    color: #2C3E50;
}

.message {

    color: #323233;
}

/*
 * Sous-categorie
 */
.menu.navigation_p {
    background: #5E84AA;
}

.slide-text > div {
    //background:#2C3E50;
}

.btn-toggle-menu, .btn-toggle-menu-none {
    background-color: #748fc9;
}

.sous-titre {
    color: white;
    background: #222222;
}

.produits .addbasket {

    border: 1px solid white;
}

/* Diapo produits page d'accueil */
.produits-accueil .liens-mask {
    background-color: rgba(116, 143, 201, .85);
}

.produits-accueil .produits .addbasket:hover, .produits-accueil .produits .details:hover {
    background-color: #5E84AA;
    border-color: #5E84AA;
    color: #fff;
}

.photo .info-photo {
    background-color: #fff;
}

.photo .info-photo p {

}

.produits .addbasket {
    border: 3px solid rgb(255, 255, 255);
}

.photo .info-photo a {
    background-position: 0 -62px;
    color: #555555;
}

.photo .info-photo a:hover {
    background-position: 0 -30px;
    color: #555555;
}

.produit_galerie_border_p {
    border-color: #2C3E50;
}

.produits .nomprod {
    background-color: #5E84AA;
    color: #fff;
}

.produits .productImageWrap {
    background-color: #fff;
}

.btn-boutique-add select {
    background: #5E84AA;
    color: #fff;
}

.produits .remise {
    color: #fff;
    background-color: #5E84AA;
}

.produits .oldprix {
    color: red;
}

.title_section, h2, .h2 {
    color: #5E84AA;
    border-bottom: 2px solid #5E84AA;
    a, a:hover {
        color: #5E84AA;
    }
}

.message_lo {
    border: 1px solid white;
    background: white;
}

.message_lo .note {
    background-color: #2C3E50;
    border-left-color: #FFFFFF;
    color: #FFFFFF;
}

.template_livreor_2 {
    .message_lo {
        &, &:hover {
            .IDLO {
                background: #fff;
            }
            &:nth-child(2n+1) .contenuMsgLO, .contenuMsgLO {
                &, .blcFleche::before {
                    background: #fff;
                }
            }
        }
    }
}

.template_livreor_3 .message_lo {
    background: #fff;
}

.footer_p {
    background: none repeat scroll 0 0 rgb(34, 34, 34);
    color: #fff;
    a {
        color: #fff;
    }
}

.button, .produits .addbasket, .links_p .button {
    border: 2px solid #2C3E50;
    color: #5E84AA;
}

.produits .zoom {
    color: #fff;
    background-color: #5E84AA;
}

.galerie .mask {
    background: #2C3E50;
}

/* Template Panier */

$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #5E84AA;
    border-color: #5E84AA;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #5E84AA;
    color: #5E84AA;

    a, span {
        color: #5E84AA;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}